import React, { useState, useEffect, Suspense } from 'react';
import { Link } from 'react-router-dom';

function Product() {
  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://api.hyginewatersolution.com/api/products', { // Make sure this URL matches your backend
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // Check response status
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status} - ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        setProducts(data.data); // Assuming your API response structure
        const initialQuantities = {};
        data.data.forEach(product => {
          initialQuantities[product.productname] = 1; // Ensure the field matches your database
        });
        setQuantities(initialQuantities);
      })
      .catch((err) => {
        setError(err.message);
        console.error('Fetch error:', err);
      });
  }, []);

  const handleQuantityChange = (productName, e) => {
    setQuantities({
      ...quantities,
      [productName]: Number(e.target.value),
    });
  };

  const handleBuyNow = (productName) => {
    const quantity = quantities[productName];
    const message = `Product: ${productName}\nQuantity: ${quantity}`;
    const encodedMessage = encodeURIComponent(message);
    const phoneNumber = '919822261313';  
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    
    window.open(whatsappURL, '_blank');
  };

  const Placeholder = () => (
    <div style={{ height: '200px', background: '#f0f0f0', borderRadius: '10px' }}></div>
  );

  return (
    <div>
      <div className="container-fluid bg-breadcrumb">
        <div className="container text-center py-5" style={{ maxWidth: '900px' }}>
          <h4 className="text-white display-4 mb-4">Products</h4>
          <ol className="breadcrumb d-flex justify-content-center mb-0">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
            <li className="breadcrumb-item active text-primary">Product</li>
          </ol>
        </div>
      </div>

      <div className="container-fluid product py-5">
        <div className="container py-5">
          <div className="text-center mx-auto pb-5" style={{ maxWidth: '800px' }}>
            <h4 className="text-uppercase text-primary">Our Products</h4>
            <h1 className="display-3 text-capitalize mb-3">We Provide the Best Water Purifiers.</h1>
          </div>

          {error ? (
            <p>Error fetching products: {error}</p>
          ) : (
            <div className="row g-4 justify-content-center">
              {products.length === 0 ? (
                <p>Loading products...</p>
              ) : (
                products.map((product, index) => (
                  <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className="product-item">
                      <Suspense fallback={<Placeholder />}>
                        <img 
                          src={`https://api.hyginewatersolution.com${product.productimage}`} // Use the correct image path
                          className="img-fluid w-100 rounded-top"
                          alt={product.productname} // Use the correct field name
                          style={{ height: '330px', objectFit: 'cover', display: 'block', width: '300px' }}
                          loading="lazy"
                          onError={(e) => {
                            console.error("Image load error for:", product.productimage);
                            e.target.src = '/path/to/placeholder.png'; // fallback to placeholder if image fails
                          }}
                        />
                      </Suspense>
                      <div className="product-content bg-light text-center rounded-bottom p-1" style={{ padding: '10px 5px' }}>
                        <p style={{ fontSize: '14px', margin: '0' }}>{product.productname}</p> {/* Ensure correct property name */}
                        <input
                          type="number"
                          min="1"
                          value={quantities[product.productname]} // Ensure correct property name
                          onChange={(e) => handleQuantityChange(product.productname, e)}
                          className="form-control mb-2"
                          style={{ width: '50px', margin: '0 auto' }}
                        />
                        <button
                          onClick={() => handleBuyNow(product.productname)}
                          className="btn btn-sm btn-primary"
                          style={{ fontSize: '20px',  backgroundColor:'#03045E' }}
                        >
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Product;
